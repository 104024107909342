import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { IoLogoInstagram } from 'react-icons/io';
import SocialProfile from '../../../../components/SocialProfile/SocialProfile';
import { Colored, IntroWrapper, IntroImage, IntroTitle, Desciption, IntroInfo } from './style';

type IntroProps = {};

const SocialLinks = [
  {
    icon: <IoLogoInstagram />,
    url: 'https://instagram.com/briedownes',
    tooltip: 'Instagram',
  },
];

const Intro: React.FunctionComponent<IntroProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/brielle.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          about
        }
      }
    }
  `);

  const { about } = Data.site.siteMetadata;
  const AuthorImage = Data.avatar.childImageSharp.fluid;

  return (
    <IntroWrapper>
      <IntroImage>
        <Image fluid={AuthorImage} alt="author" />
      </IntroImage>
      <IntroInfo>
        <IntroTitle>
          Let me introduce myself
          <Colored>...</Colored>
        </IntroTitle>
        <Desciption>{about}</Desciption>
        <SocialProfile items={SocialLinks} />
      </IntroInfo>
    </IntroWrapper>
  );
};

export default Intro;
